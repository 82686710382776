import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/views/home/index.vue'),
  },
  {
    path: '/h5Manage',
    component: () => import('@/views/h5Manage/index.vue'),
    // meta: {
    //   title: '隐私政策',
    // },
  },

  // {
  //   path: '/payment/ipay88/request',
  //   component: () => import('@/views/payment/ipay88/index.vue')
  // },
  {
    // path: '/payment/ipay88/response',
    path: '/payment/response',
    component: () => import('@/views/payment/ipay88/response.vue'),
  },
  {
    path: '/payment/ipay88/selectPayMethod',
    component: () => import('@/views/payment/ipay88/payMethod.vue'),
  },
  {
    path: '/test',
    component: () => import('@/views/test/index.vue'),
  },
]

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior: () => {
    return {
      top: 0,
    }
  },
})

// 白名单
const whiteList = ['/login']

router.beforeEach(async (to, from) => {
  if (to.path === '/payment/ipay88/response') {
    if (window.flutter_inappwebview) {
      const orderNo = localStorage.getItem('orderNo')
      const totalActualAmount = localStorage.getItem('totalActualAmount')
      const isMultipeStore = localStorage.getItem('isMultipeStore')
      const params = JSON.stringify({
        orderNo,
        payAmount: totalActualAmount,
        isMultipeStore: isMultipeStore,
        payStatus: 'PROCESSING',
      })
      console.log(params)

      window.flutter_inappwebview?.callHandler(
        'jsToFlutter',
        `router*mybeauty*pay_result*mybeauty*${params}*mybeauty*offWebview`
      )
      return false
    }
    return true
  }
  document.title = to.meta.title || ''
  // next()
  return true
})
export default router
