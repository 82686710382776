import type { App } from 'vue'
import { installVant } from './vant'
import { installGlobalComponents } from './mb'

const installUi = async (app: App) => {
  installVant(app)
  await installGlobalComponents(app)
}

export default installUi
