import {
  Button,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  NavBar,
  Col,
  Row,
  Badge,
  Form,
  Field,
  Cell,
  CellGroup,
  Sticky,
  Toast,
  RadioGroup,
  Radio,
  Popup,
  DropdownMenu,
  DropdownItem,
  setToastDefaultOptions,
  Grid,
  GridItem,
} from 'vant'
import 'vant/es/notify/style'
import 'vant/es/toast/style'
import type { App } from 'vue'

setToastDefaultOptions('loading', { forbidClick: true })

const components = [
  Button,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  NavBar,
  Col,
  Row,
  Badge,
  Form,
  Field,
  Cell,
  CellGroup,
  Sticky,
  RadioGroup,
  Toast,
  Radio,
  Popup,
  DropdownMenu,
  DropdownItem,
  Grid,
  GridItem,
]

export const installVant = (app: App) => {
  components.forEach((component) => {
    app.use(component, component.name as any)
  })
}
