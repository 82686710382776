import { createApp } from 'vue'
import App from './App.vue'
import 'amfe-flexible/index.min.js'
import 'normalize.css'
import '@/styles/index.scss'
import install from './install'

const app = createApp(App)
install(app).then(() => {
  app.mount('#app')
})
