import type { App } from 'vue'
import router from './router'
import installUi from './ui'
import { createPinia } from 'pinia'

export default async (app: App) => {
  await installUi(app)
  const pinia = createPinia()
  app.use(router)
  app.use(pinia)
}
