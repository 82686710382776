// 注册src/components目录下的所有组件

import type { App } from 'vue'

const nameReg = /components\/(\w+)\/src\/index\.vue/

const modules = import.meta.glob('@/components/**/src/*.vue')

export const installGlobalComponents = async (app: App) => {
  for (const key in modules) {
    const match = nameReg.exec(key)
    if (!match) {
      throw new Error('组件名找不到')
    }
    let componentName = match[1]
    componentName =
      componentName[0].toLocaleUpperCase() + componentName.substring(1)
    // console.log(componentName)

    await modules[key]().then((component) => {
      const c = component as Record<string, any>
      app.component(componentName, c.default)
    })
  }
  console.log('%cglobal components register success', 'color: green;')
}
